import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=74bba95e&scoped=true"
var script = {}
import style0 from "./NotFound.vue?vue&type=style&index=0&id=74bba95e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74bba95e",
  null
  
)

export default component.exports